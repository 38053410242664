import { useCallback } from 'react';
import {
  Button,
  Card,
  Flex,
  Tooltip,
  Typography,
} from 'antd';
import cn from 'classnames';
import { PlayCircleFilled } from '@ant-design/icons';

import usePreSignedAudioUrl from '../../../../hooks/data/usePreSignedAudioUrl';
import { formatMinutesDuration } from '../../../../utils/date/time';
import { useAudioPlayer } from '../../../audioPlayer';
import { CallTranscript, GetPresignedUrlRequest } from '../../../../types';

import './index.scss';

interface MessageProps {
  role: string;
  content: string;
  index: number;
  words?: CallTranscript['words'];
  audioUrl: string | null;
  getPreSignedUrlRequest: GetPresignedUrlRequest;
}

const transcriptDelay = 2.5;

const Message = ({
  role,
  content,
  words,
  index,
  audioUrl,
  getPreSignedUrlRequest,
}: MessageProps) => {
  const { open, currentlyPlayingId } = useAudioPlayer();

  const { fetchData } = usePreSignedAudioUrl({ audioUrl, request: getPreSignedUrlRequest });

  const isUser = role === 'user';

  const audioPlayerId = `${role}-${content}-${index}`;

  const onPlayButtonClick = useCallback(async () => {
    const preSignedUrl = await fetchData();

    if (preSignedUrl && words) {
      open({
        id: audioPlayerId,
        url: preSignedUrl,
        from: words[0].start + transcriptDelay,
        to: words[words.length - 1].end + transcriptDelay,
      });
    }
  }, [audioPlayerId, fetchData, open, words]);

  return (
    <Card
      size="small"
      className={cn('transcript-message', {
        'user-message': isUser,
      })}
    >
      <Card.Meta
        title={(
          <Flex gap={8} align="center">
            <Typography.Text strong className="capitalize">
              {role}
            </Typography.Text>
            {!!words?.length && (
              <Typography.Text className="timing">
                {formatMinutesDuration(words[0].start)}
                {' '}
                -
                {' '}
                {formatMinutesDuration(words[words.length - 1].end)}
              </Typography.Text>
            )}
            <Tooltip title={words?.length ? 'Play audio' : 'Message has no content'}>
              <Button
                className="play-audio-button"
                type="primary"
                ghost={currentlyPlayingId !== audioPlayerId}
                size="small"
                icon={<PlayCircleFilled />}
                onClick={onPlayButtonClick}
                disabled={!audioUrl || !words?.length}
              />
            </Tooltip>
          </Flex>
        )}
        description={<Typography.Text>{content}</Typography.Text>}
      />
    </Card>
  );
};

export default Message;
