import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);
dayjs.extend(duration);

export const formatMinutesDuration = (rawSeconds?: number): string => {
  if (rawSeconds === 0) {
    return '0 s';
  }

  if (!rawSeconds) {
    return '';
  }

  return dayjs.duration(rawSeconds, 's').format('mm:ss:SSS').replace(/\..+/, '');
};

export default formatMinutesDuration;
