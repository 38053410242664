import { useMemo } from 'react';
import {
  Card,
  Empty,
  Flex,
  Spin,
} from 'antd';

import Message from './components/message';
import { parseTranscript } from '../../utils/transcript';
import { GetPresignedUrlRequest } from '../../types';

import './index.scss';

interface CallTranscriptProps {
  loading?: boolean;
  data?: string[] | string | null;
  audioUrl: string | null;
  getPreSignedUrlRequest: GetPresignedUrlRequest;
}

const CallTranscript = ({
  loading,
  data,
  audioUrl,
  getPreSignedUrlRequest,
}: CallTranscriptProps) => {
  const parsedTranscript = useMemo(() => parseTranscript(data), [data]);

  return (
    <Card
      title="Transcript"
      className="bg-white rounded shadow-lg mb-6"
    >
      <Flex gap={10} vertical className="transcript-messages-list">
        {loading && (
          <Spin className="m-auto" size="large" />
        )}
        {!loading && !parsedTranscript?.length && (
          <Empty className="m-auto" />
        )}
        {!loading && !!parsedTranscript?.length && (
          parsedTranscript.map(({ role, content, words }, index) => (
            <Message
              // eslint-disable-next-line react/no-array-index-key
              key={`${role}-${content}-${index}`}
              role={role}
              content={content}
              audioUrl={audioUrl}
              words={words}
              index={index}
              getPreSignedUrlRequest={getPreSignedUrlRequest}
            />
          )))}
      </Flex>
    </Card>
  );
};

export default CallTranscript;
