import {
  Button,
  Card,
  Flex,
  Pagination,
  Table,
  Typography,
} from 'antd';
import { CloseOutlined, ReloadOutlined } from '@ant-design/icons';
import useSearchDebounce from '@marrlab-app-shared/hooks/state/useSearchDebounce';
import FiltersPanel from '@marrlab-app-shared/components/filtersPanel';

import Search from './components/search';
import CampaignFilter from './components/campaignFilter';
import CustomersProvider from '../../components/customersProvider';
import ReviewStatusFilter from './components/reviewStatusFilter';

import useCallsHistoryData from './hooks/useCallsHistoryData';
import usePagination from './hooks/usePagination';
import useSorting from './hooks/useSorting';
import useTableConfiguration from './hooks/useTableConfiguration';
import useFilters from './hooks/useFilters';
import { idColumnKey } from './constants/columnsInitialState';
import { getDefaultSearch } from './utils/searchParams';

import './index.scss';

const CallsHistory = () => {
  const { currentCustomer } = CustomersProvider.useCurrentCustomer();

  const {
    columns,
    sticky,
    scroll,
    setColumns,
    onRow,
  } = useTableConfiguration();
  const {
    skip,
    page,
    pageSize,
    resetPage,
    onPageChange,
    showTotal,
  } = usePagination();
  const { search, updateSearch } = useSearchDebounce({
    onDebounce: resetPage,
    defaultSearch: getDefaultSearch(),
  });
  const { filters, onFiltersUpdate, clearFilters } = useFilters({ resetPage });
  const { sortBy, sortDirection, onSortChange } = useSorting({ setColumns });
  const { data, loading, fetchCallsHistory } = useCallsHistoryData({
    skip,
    take: pageSize,
    sortBy,
    order: sortDirection,
    search,
    customer: currentCustomer,
    ...filters,
  });

  return (
    <Card
      title={<Typography.Title level={4}>Tasks</Typography.Title>}
      className="bg-white rounded shadow-lg"
    >
      <FiltersPanel
        search={(
          <Flex gap={16}>
            <Search
              updateSearch={updateSearch}
              defaultSearch={search}
            />
            <Button icon={<ReloadOutlined />} onClick={fetchCallsHistory}>Reload</Button>
          </Flex>
        )}
      >
        <Flex gap={16} vertical>
          <Flex gap={16} wrap="wrap">
            <ReviewStatusFilter filters={filters} onFiltersUpdate={onFiltersUpdate} />
            <CampaignFilter filters={filters} onFiltersUpdate={onFiltersUpdate} />
          </Flex>
          <Flex justify="flex-end">
            <Button danger onClick={clearFilters} icon={<CloseOutlined />}>Clear filters</Button>
          </Flex>
        </Flex>
      </FiltersPanel>
      <div className="table-wrapper">
        <Table
          rowKey={idColumnKey}
          dataSource={data?.data}
          columns={columns}
          loading={loading}
          scroll={scroll}
          pagination={false}
          sticky={sticky}
          onChange={onSortChange}
          onRow={onRow}
        />
      </div>
      <Flex justify="flex-end">
        <Pagination
          hideOnSinglePage
          className="mt-4"
          total={data?.total}
          showTotal={showTotal}
          pageSize={pageSize}
          current={page}
          showSizeChanger
          onChange={onPageChange}
          disabled={loading}
        />
      </Flex>
    </Card>
  );
};

export default CallsHistory;
