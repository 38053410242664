import { TableColumnsType, Typography } from 'antd';

import { CallHistory } from '../../../api/callsHistory/types';
import { formatDate } from '../../../utils/date/date';
import { formatDuration } from '../../../utils/date/time';
import { ReviewStatusNames } from '../../../constants';

export const idColumnKey = 'callLogId';

export const emptyFilterValue = '-1';

export const columnsInitialState: TableColumnsType<CallHistory> = [
  {
    title: 'ID',
    dataIndex: idColumnKey,
    key: idColumnKey,
    fixed: 'left',
    sorter: true,
    width: 80,
    render: (text: number) => <Typography.Link>{text}</Typography.Link>,
  },
  {
    title: 'Review status',
    dataIndex: 'reviewStatus',
    key: 'reviewStatus',
    sorter: true,
    width: 120,
    render: (_, record) => (record.userInfo.some((userInfo) => userInfo.source === 3)
      ? ReviewStatusNames.reviewed
      : ReviewStatusNames.toBeReviewed),
  },
  {
    title: 'Call uuid',
    dataIndex: 'callLogUuid',
    key: 'callLogUuid',
    sorter: true,
    width: 330,
  },
  {
    title: 'Started',
    dataIndex: 'startDate',
    key: 'startDate',
    sorter: true,
    width: 200,
    render: (text: string) => formatDate(text, true),
  },
  {
    align: 'right',
    title: 'Duration',
    dataIndex: 'callDurationSec',
    key: 'callDurationSec',
    sorter: true,
    width: 110,
    render: (text?: number) => formatDuration(text),
  },
  {
    title: 'Agent number',
    dataIndex: 'agentNumber',
    key: 'agentNumber',
    sorter: true,
    width: 120,
  },
  {
    title: 'User number',
    dataIndex: 'userNumber',
    key: 'userNumber',
    sorter: true,
    width: 120,
  },
  {
    title: 'Origination number',
    dataIndex: 'operatorNumber',
    key: 'operatorNumber',
    sorter: true,
    width: 120,
  },
  {
    title: 'Customer',
    dataIndex: 'customer',
    key: 'customer',
    sorter: true,
    width: 110,
    render: ((_, record) => record.orchestratorProvider?.campaign?.customer?.name),
  },
  {
    title: 'Campaign',
    dataIndex: 'campaign',
    key: 'campaign',
    sorter: true,
    width: 120,
    render: ((_, record) => record.orchestratorProvider?.campaign?.displayName),
  },
];
